.hero-sections {
  background: url(./../../../../assets/img/background/header-bg-computer.jpg)
    no-repeat 50%;

  .logo {
    width: 98px;
    height: 95px;
    margin-bottom: 30px;
    display: inline;
  }

  .description {
    padding: 80px 20px;
    @media screen and (min-width: 992px) {
      padding: 80px 0px;
    }
    h1 {
      font-size: 30px;
      font-weight: 600;
      color: #ffffff;
      @media screen and (min-width: 992px) {
        font-size: 60px;
      }
      span {
        font-weight: 700;
      }
    }
  }

  .arrow {
    position: absolute;
    animation: shakeArrow 2s infinite linear 600ms;

    > p {
      display: none;
    }
    i {
      font-size: 30px;
    }
  }
}

@keyframes shakeArrow {
  0%,
  100% {
    transform: translateY(0px);
  }
  20% {
    transform: translateY(-5px);
  }
  40% {
    transform: translateY(0px);
  }
  60% {
    transform: translateY(5px);
  }
}
