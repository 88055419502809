@import './../../assets/css/color.scss';
.box-title {
  text-align: center;
  margin-top: 50px;
  h2 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 0px;
    color: $white-color;
    &.blue {
      color: $slate-blue;
    }
    @media screen and (min-width: 768px) {
      font-size: 50px;
    }
  }
  .line {
    width: 50px;
    height: 3px;
    background: $electric-purple;
    display: inline-block;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    margin-top: 30px;
    margin-bottom: 50px;
    color: $white-color;
    &.blue {
      color: $slate-blue;
    }
    @media screen and (min-width: 768px) {
      font-size: 20px;
    }
  }
}
