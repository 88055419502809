@import './assets/css/color.scss';
.App {
  text-align: center;
  .filter {
    height: 100%;
    overflow: auto;
    width: 100%;
    background: linear-gradient(
      282deg,
      rgba(0, 0, 0, 0.73),
      rgba(27, 76, 132, 0.73)
    );
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 80px 0;
  }
  .bg-fixed {
    background-size: cover;
    background-attachment: scroll;
    height: 100%;
    overflow: auto;
    position: relative;
    @media screen and (min-width: 992px) {
      background-attachment: fixed;
    }
  }

  #skippy {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    top: -10px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
}
