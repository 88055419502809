body,
code,
html {
  font-family: 'Open Sans', sans-serif;
  font-optical-sizing: auto;
  margin: 0;
  height: 100%;
  width: 100%;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 400;

  .home {
    padding-right: 0px;
    padding-left: 0px;
    height: 100%;
  }
}
