@import './../../assets/css/color.scss';
footer {
  color: $white-color;
  font-size: 16px;
  text-align: center;
  padding: 30px 20px 0px;
  position: relative;
  @media screen and (min-width: 768px) {
    padding: 30px 0px 0px;
  }
  .to-top {
    color: $white-color;
    position: absolute;
    margin: 0 auto;
    animation: shakeArrow 2s infinite linear 600ms;
    top: -60%;
    p {
      font-size: 0px;
    }
    i {
      font-size: 30px;
    }
  }
}

@keyframes shakeArrow {
  0%,
  100% {
    transform: translateY(0px);
  }
  20% {
    transform: translateY(-5px);
  }
  40% {
    transform: translateY(0px);
  }
  60% {
    transform: translateY(5px);
  }
}
