@import './../../assets/css/color.scss';
nav {
  &.navbar {
    background: $slate-blue;
    position: fixed;
    width: 100%;
    z-index: 10;
    top: 0;
    @media screen and (min-width: 992px) {
      background: transparent;
      position: absolute;
      background-color: transparent;
      top: 30px;
      &.menu-fixed {
        position: fixed;
        background-color: $slate-blue;
        top: 0;
        transition: 0.4s;
      }
    }

    .navbar-nav {
      width: 100%;
      height: 100%;
      justify-content: center;

      .nav-item {
        margin: 0px;
        position: relative;
        @media screen and (min-width: 992px) {
          margin: 10px;
        }

        &::after {
          position: absolute;
          content: '';
          top: 14px;
          left: -10px;
          color: #ffffff;
          font-size: 20px;
          @media screen and (min-width: 992px) {
            content: '.';
          }
        }

        &:first-child::after {
          content: '';
        }
      }
      .nav-link {
        font-size: 30px;
        text-align: center;
        padding: 20px 0;
        font-weight: 700;
        letter-spacing: 1px;

        @media screen and (min-width: 992px) {
          font-size: 20px;
          padding: 16px 32px;
        }
      }
    }

    .button-menu {
      margin: 16px 20px 16px 16px;

      .hamburger {
        position: relative;
        width: 32px;
        height: 32px;
        right: 0px;
        top: 0;
        display: inline-block;
        transition: 0.4s;
        transform: rotate(180deg);

        &.show {
          .bars-one {
            transform: rotate(45deg) translate3d(7px, 7px, 0);
            top: 0px !important;
          }

          .bars-two {
            opacity: 0 !important;
          }

          .bars-three {
            transform: rotate(-45deg) translate3d(-7px, 7px, 0);
            top: 0px !important;
          }
        }

        .bars {
          position: absolute;
          width: 100%;
          height: 2px;
          background: $white-color;
          transition: 0.2s;
          left: 0;
          top: 0;

          &.bars-one {
            top: 2px;
          }

          &.bars-two {
            top: 10px;
            opacity: 1;
          }

          &.bars-three {
            top: 18px;
          }
        }
      }

      &.show {
        .bars-one {
          transform: rotate(45deg) translate3d(7px, 7px, 0);
          top: 0px !important;
        }

        .bars-two {
          opacity: 0 !important;
        }

        .bars-three {
          transform: rotate(-45deg) translate3d(-7px, 7px, 0);
          top: 0px !important;
        }
      }
    }
  }
}
